<template>
    <div id="main">
        <div v-if="message">{{message}}</div> 
        <div v-if="ready" >
            <div v-if="editmode==false" >
              <div class="datahtml" v-html="html"></div>          
              <button v-if="int_editable=='true'" @click="editer()">Editer</button>
            </div>
            <div v-else>
                <editor v-model="html" :num="name"/>
                <icon-button @click="enregistrer()" tooltip="Enregistrer" type="save"/>
                <icon-button @click="annuler()" tooltip="Annuler" type="cancel"/>
    
               <!-- <div style="margin-top:40px">
                  <h3>Pévisualiser</h3>
                  <div class="datahtml"  v-html="html"></div>
                </div>-->
            </div>
        </div>
    </div>        
    </template>
    
    <script>
    import axios from 'axios';
    import Editor from '@/components/articles/TipTap.vue'
    import IconButton from '@/components/custom/iconbutton.vue';

    export default {
      name: 'LbArticle',
      
      components : {Editor,IconButton},
      props: {
        name:String,
      },
      data() {
          return {
              html: "",
              ready: false,
              message: "Chargement...",
              editmode : true,
              int_editable : false,
          }
      },
      created : function() {
          this.lire();
          this.isEditable();
      },
      methods: {
        isEditable() {
            var baseurl = "/api/";
            this.int_editable=false;
            
            axios.get(baseurl+'?islogged').then(response =>{
                if (response.data==1) {
                    this.int_editable=this.editable;
                }
                
            }).catch(error => {
                console.log(error);
                
            })
        },
        lire() {
          var baseurl = "/api/"
          //  var baseurl = "/data"
          axios.get(baseurl+'?article='+this.name).then(response =>{
            this.html = response.data; 
            this.ready=true;
            this.message="";
          }).catch(error => {
              this.message=error;
              this.ready=true;
          })
        },
        enregistrer() {
            //this.editmode=false;
          
            var baseurl = "/api/"
            //  var baseurl = "/data"
            axios.post(baseurl,{
              id:this.name,
              texte:this.html
            }).then(response =>{
              this.message = response.data; 
              this.ready=true; 
              this.$emit("enregistre");    
          }).catch(error => {
              this.message=error;
              this.ready=true;
          })
          },
        editer() {
            this.editmode=true;
            this.message="";
          },
          annuler() {
            this.$emit("enregistre"); 
          }
      }
    }
    </script> 
    <style>
        /*.datahtml {
            -moz-animation-duration: ;display:block;
            margin-left: auto;
            margin-right: auto;
            width:100%;
            height:auto;		
        }*/
      .datahtml img {
        display:block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .datahtml table {
      width:50%;
    }

    .datahtml tr {
      display:flex;
    }
    .datahtml td {
      /*text-align: center;*/
      vertical-align: middle;
    }
    /* Gazette */
        .gazette img {
            display:block;
            margin-left: auto;
            margin-right: auto;
        }
        .gazette .date {
                text-align: center;
                display: block;
                font-size: 1rem;
                height: 1;
                margin: 0 0 1rem 0;
                position: relative;
        }
    </style>
