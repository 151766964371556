import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from "axios"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {requiresAuth: true} // Indicates that this route requires authentication
  },
  {
    path: '/auth',
    name: 'auth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/LoginView.vue')
  },
  {
    path: '/calc',
    name: 'clac',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/CalView.vue'),
    meta: {requiresAuth: true} // Indicates that this route requires authentication
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {  
  // Determine if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
      // Get value from somewhere to determine if the user is 
      // logged in or not
      /*let isLoggedIn = false;*/
      var baseurl = "/api/";
      
      axios.get(baseurl+'?islogged').then(response =>{
          if (response.data==0) {
            /*isLoggedIn=true;*/
            next({
              name: "auth",
              query: {redirect: to.fullPath}
          });
          } else {
            next();
          }
          
      })

  } else {
      next();
  }
});



export default router
