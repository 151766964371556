<template>
<button class="btnenreg tooltip">
    <img v-if="type=='add'" src="@/assets/icons/ajouter.png" />
    <img v-if="type=='del'" src="@/assets/icons/supprimer.png" />
    <img v-if="type=='save'" src="@/assets/icons/enregistrer.png" />
    <img v-if="type=='edit'" src="@/assets/icons/editer.png" />
    <img v-if="type=='cancel'" src="@/assets/icons/back.png" />
    <span class="tooltiptext">{{tooltip}}</span>
</button>
</template>

<script>

export default {
  name: 'IconButton',

  props : {tooltip: String,
    type: String}

}

</script>

<style scoped>

button {
    padding:0;
    width:32px;
    height: 32px;
    background-color:rgba(255, 255, 255, 0);
    border: none;
}


 /* Tooltip container */
 .tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} 


</style>