<template>
<ul class="imageedit">
	<li v-for="item in imagelist" :key="item" >  
		<button @click="$emit('add','/api/?image='+item)" >
		<img  :src="'/api/?image='+item" /></button>
	</li>
	<li v-for="item in uploadableFiles" :key="item.id" class="file-preview">
		<button class="close-icon" @click="onRemove(item)" >&times;</button>
		<img  :src="item.url" />
		<span class="status-indicator loading-indicator" v-show="item.status == 'loading'">In Progress</span>
		<span class="status-indicator success-indicator" v-show="item.status == true">Uploaded</span>
		<span class="status-indicator failure-indicator" v-show="item.status == false">Error</span>
	</li>
	<li>
		<label for="file-input">
			<!--<button @click="onUpload()">-->
			<img class="iconupload" src="@/assets/icons/file-upload-line48.png"/>
			<span>Clic pour Upload</span>
			<!--</button>-->
			<input type="file" id="file-input" multiple @change="onInputChange" />        
		</label>
	</li>
</ul>
</template>

<script>

import axios from 'axios';

class UploadableFile {
	constructor(file) {
		this.file = file
		this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
		this.url = URL.createObjectURL(file)
		this.status = null
	}
}


export default {

  props: {
	articleid : String,
  },

  data() {
	return {
		imagelist : [],
		uploadableFiles : [],
	}
  },

  methods:{
      listimages() {
        axios.get("/api/?images="+this.articleid).then(response =>{
          this.imagelist = response.data; 
        }).catch(() => {

        })
      },

      onInputChange(e) {
		var newFiles=e.target.files;
		//this.uploadableFiles = [...newFiles].map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id))
		this.uploadableFiles = [...newFiles].map((file) => new UploadableFile(file))

		e.target.value = null;
		this.uploadFiles(this.uploadableFiles);
      },

	fileExists(otherId) {
		return this.uploadableFiles.value.some(({ id }) => id === otherId)
	},


	//uploadFile(file) {
	async uploadFile(file) {
		// set up the request data
		let formData = new FormData()
		formData.append('fichier', file.file)
		formData.append('article', this.articleid)

		// track status and upload file
		file.status = 'loading'
		let response = await fetch("/api/?image", { method: 'POST', body: formData })

		// change status to indicate the success of the upload request
		file.status = response.ok
		return response
	},

	uploadFiles(files, url) {
	return Promise.all(files.map((file) => this.uploadFile(file, url)))
	},


	onRemove() {
	}


  },

  mounted() {
      this.listimages(); 
  }

}

</script>

<style lang="scss" scoped>
.imageedit {
	display: flex;
	list-style: none;
	flex-wrap: wrap;
	padding: 0;
	background-color:#adf;
	width: 100%;
}

.imageedit li {
	width: 20%;
	//margin: 1rem 2.5%;
	position: relative;
	aspect-ratio: 1/1;
	overflow: hidden;
}
.imageedit .iconupload {
	width: 20%;
	height: 20%;
}

.imageedit img {
	width: 90%;
	height: 90%;
	display: block;
	object-fit: cover;
	margin-left: auto;
	margin-right: auto;
}

.imageedit button {
	width: 100%;
	height: 100%;
}

.imageedit label {
	font-size: 1rem;
	cursor: pointer;
	display: block;

	span {
		display: block;
		text-align: center;
	}

	input[type=file]:not(:focus-visible) {
		// Visually Hidden Styles taken from Bootstrap 5
		position: absolute !important;
		width: 1px !important;
		height: 1px !important;
		padding: 0 !important;
		margin: -1px !important;
		overflow: hidden !important;
		clip: rect(0, 0, 0, 0) !important;
		white-space: nowrap !important;
		border: 0 !important;
	}
}

.file-preview {
	width: 20%;
	margin: 1rem 2.5%;
	position: relative;
	aspect-ratio: 1/1;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}

	.close-icon, .status-indicator {
		--size: 20px;
		position: absolute;
		line-height: var(--size);
		height: var(--size);
		border-radius: var(--size);
		box-shadow: 0 0 5px currentColor;
		right: 0.25rem;
		appearance: none;
		border: 0;
		padding: 0;
	}

	.close-icon {
		width: var(--size);
		font-size: var(--size);
		background: #933;
		color: #fff;
		top: 0.25rem;
		cursor: pointer;
	}

	.status-indicator {
		font-size: calc(0.75 * var(--size));
		bottom: 0.25rem;
		padding: 0 10px;
	}

	.loading-indicator {
		animation: pulse 1.5s linear 0s infinite;
		color: #000;
	}

	.success-indicator {
		background: #6c6;
		color: #040;
	}

	.failure-indicator {
		background: #933;
		color: #fff;
	}
}

@keyframes pulse {
	0% {
		background: #fff;
	}

	50% {
		background: #ddd;
	}

	100% {
		background: #fff;
	}
}

</style>
