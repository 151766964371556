<template>
  <div class="home">
    <Articles />
  </div>
</template>

<script>
// @ is an alias to /src
import Articles from '@/components/articles/Articles.vue'

export default {
  components: {
    Articles
  }
}
</script>
