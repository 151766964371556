<template>
    <div id="main">
      <div v-if="message">{{message}}</div> 
      
      <div v-else>        
        <div v-if="selectednum==-1">
          <div class="articles" v-for="(art,i) in articles" :key="i" >
            <div class="article">
              {{ art["name"] }}
              <icon-button class="btnedit" @click="selectednum=art['id']" tooltip="Modifier l'article" type="edit"/>
              <ul v-for="(sub,j) in art['submenu'] " :key="j">
                <li>
                  {{ sub["name"] }}
                  <icon-button class="btnedit" @click="selectednum=sub" tooltip="Modifier l'article" type="edit"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="editeur" v-else><Article :name="selectednum" editable="true" @enregistre="selectednum=-1" /></div>
      </div>
      
    </div>        
    </template>
    
    <script>
    import axios from 'axios';
    import Article from '@/components/articles/Article.vue';
    import IconButton from '@/components/custom/iconbutton.vue';
    
    export default {
      name: 'LbArticles',
      
      components : {Article, IconButton},
      
      data() {
          return {
              ready: false,
              message: "Chargement...",
              selectednum : -1,
              articles : []
          }
      },
      created : function() {
          this.lire();
      },

      methods: {
        lire() {
          var baseurl = "/api/"
          //  var baseurl = "/data"
          axios.get(baseurl+'?articles').then(response =>{
            this.articles = response.data; 
            this.ready=true;
            this.message="";
          }).catch(error => {
              this.message=error;
              this.ready=true;
          })
        },
      }
    }
    </script> 
    <style scoped>

.articles {
  display: grid;
  grid-template-columns: 300px 100px 1fr;
  grid-gap: 1px;
  align-items: center;
  /*grid-auto-rows: minmax(100px, auto);*/
}

.article {
  grid-column: 1;
  grid-row: 1;
  margin : 2px;
  background-color: antiquewhite;
  
}
.btnedit {
  grid-column:2;
  grid-row:1;
  margin: 10px ;
}

.editeur {
  grid-column:3;
  grid-row:1;
  
}


    </style>
